import React, { useState, useEffect, useRef } from "react"
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Layout, Seo, Container, RoundedBox, Button } from "../../components"
import { ThumbsUp, ThumbsDown, ArrowLeft } from '../../assets/vectors'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as styles from './style.module.scss'

import Mockup from '../../assets/img/mockup_howto.png'

const HowToPlaySingleTemplate = ({ pageContext, data }) => {
	const [ feedback, setFeedback ] = React.useState(false)

	const { howToFields, seoMetaFields } = data.howto

	const metadata = seoMetaFields.wpFields?.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

	const howToContent = howToFields.howtoFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  ).tabs[0]

	const steps = howToContent.steps
	const stepsIndex = steps.map((step, i) => step.index = i + 1)

	const [ activeSlider, setActiveSlider ] = useState(1)
	const [ slidesListActive, setSlidesListActive ] = useState(false)
	const slider = useRef()
	const menu = useRef()

	const carouselSettings = {
		autoPlay: false,
		arrows: false,
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	}

	const handleSlideChange = (e) => {
		e.preventDefault();

		const slide = e.target.getAttribute('data-slide')
		slider.current.slickGoTo(slide)
		setActiveSlider(Number(slide) + 1)
		setSlidesListActive(false)
	}

	const handleFeedback = (e) => {
		e.preventDefault();
		setFeedback(true);
	}

  return (
    <Layout>
      {/* <Seo title={metadata.seoTitle} description={metadata.seoDescription} /> */}

			<RoundedBox
				className={styles.roundedBox}
				transform="rotate(26deg)"
				width="916px"
				height="996px"
				position={{
					top: "-800px",
					right: "100px"
				}}
			/>

      <section className={styles.howToSingle}>
				<Container>
					<header className={styles.howToSingle__head}>
						<a
							className={`${styles.button} button`}
							data-content="Voltar"
							onClick={(e) => {e.preventDefault(); navigate(-1)}}
						><ArrowLeft /> {pageContext.lang.includes("pt") ? "Voltar" : "Back"}</a>

						<h1 className={styles.title}>{howToContent.title}</h1>

						<iframe className={styles.iframe} width="560" height="315" src={howToContent.video} title={howToContent.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</header>

					<div className={styles.howToSingle__body}>
						<hr />

						<div className={styles.menu__wrapper}>
							<div
								className={styles.selectedSlide}
								onClick={() => setSlidesListActive(!slidesListActive)}>
								{activeSlider}º {pageContext.lang.includes("pt") ? "Passo" : "Step"}
							</div>

							<ul ref={menu} className={styles.menu} style={{height: (slidesListActive ? menu.current.scrollHeight+"px" : '')}}>
								{
									steps.map(step =>
										<li key={Math.random()}>
											<a
												href="#"
												className={activeSlider === step.index ? styles.active : ''}
												onClick={handleSlideChange}
												data-slide={step.index - 1}
											>
												{step.title}
											</a>
										</li>
									)
								}
							</ul>
						</div>

						<Slider ref={slider} {...carouselSettings}>
							{
								steps.map(step =>
									<div key={Math.random()} className={styles.slide}>
										<div className="flex align-center">
											<div className={`${styles.slide__content} ${styles.slide__content__mobile}`}>
												<p className={styles.text}
													dangerouslySetInnerHTML={{ __html: step.content }}
												/>
											</div>
											<div className={`${styles.slide__image} card card__default card--purple`}>
												<img src={step.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt={step.title} />
											</div>
											<div className={styles.slide__content}>
												<p className={styles.title}>{step.title}</p>
												<p className={styles.text}
													dangerouslySetInnerHTML={{ __html: step.content }}
												/>

												<div className={styles.buttons__wrapper}>
													{
														step.index !== 1 ?
														<Button
															type="button"
															color="yellow"
															className={styles.button}
															label={pageContext.lang.includes("pt") ? "Anterior" : "Previous"}
															onClick={
																(e) => {
																	e.preventDefault();
																	slider.current.slickPrev();
																	setActiveSlider(activeSlider - 1);
																}
															}
														/>
														: <span></span>
													}

													{
														step.index !== steps.length &&
														<Button
															type="button"
															color="yellow"
															className={styles.button}
															label={pageContext.lang.includes("pt") ? "Próximo" : "Next"}
															onClick={
																(e) => {
																	e.preventDefault();
																	slider.current.slickNext();
																	setActiveSlider(activeSlider + 1);
																}
															}
														/>
													}
												</div>
											</div>
										</div>
									</div>
								)
							}
						</Slider>
					</div>

					{
						!feedback ?
							<div className={`${styles.howToSingle__vote} flex align-center`}>
								<p>{pageContext.lang.includes("pt") ? "Este artigo foi útil?" : "Was this tutorial helpful?"}</p>
								<a href="" className="button" data-content="Sim" onClick={handleFeedback}><ThumbsUp /> {pageContext.lang.includes("pt") ? "Sim" : "Yes"}</a>
								<a href="" className="button" data-content="Não" onClick={handleFeedback}><ThumbsDown /> {pageContext.lang.includes("pt") ? "Não" : "No"}</a>
							</div>
						: <div className={`${styles.howToSingle__vote} flex align-center`}>
								<p>{pageContext.lang.includes("pt") ? "Obrigado! Seu feedback é muito importante para nós." : "Thanks! Your feedback is very important to us."}</p>
							</div>
					}
					
				</Container>
			</section>
    </Layout>
  )
}

export const Query = graphql`query HowToSingle($id: Int) {
  howto: wpHowTo(databaseId: {eq: $id}) {
    databaseId
    slug
    seoMetaFields {
      wpFields {
        languageValue {
          slug
          name
        }
        seoTitle
        seoDescription
      }
    }
    howToFields {
      howtoFields {
        languageValue {
          name
          slug
        }
        tabs {
          title
          video
          steps {
            title
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default HowToPlaySingleTemplate
