// extracted by mini-css-extract-plugin
export var howToSingle = "style-module--how-to-single--1MkZ5";
export var howToSingle__head = "style-module--how-to-single__head--1mqFT";
export var button = "style-module--button--Fjvdo";
export var title = "style-module--title--15wK3";
export var iframe = "style-module--iframe--ax9fR";
export var howToSingle__body = "style-module--how-to-single__body--23ks8";
export var menu__wrapper = "style-module--menu__wrapper--2Mkgk";
export var selectedSlide = "style-module--selected-slide--1emFG";
export var menu = "style-module--menu--10SvN";
export var active = "style-module--active--1xupC";
export var slide = "style-module--slide--3Da8F";
export var slide__image = "style-module--slide__image--Jg44k";
export var slide__content__mobile = "style-module--slide__content__mobile--ZNF_Z";
export var slide__content = "style-module--slide__content--2MwKz";
export var text = "style-module--text--3_zES";
export var buttons__wrapper = "style-module--buttons__wrapper--LME9t";
export var howToSingle__vote = "style-module--how-to-single__vote--2SjPO";
export var roundedBox = "style-module--roundedBox--1_pn1";